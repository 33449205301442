
document.addEventListener('click', (e) => {
  if (e.target && e.target.getAttribute('data-toggle') !== '' && e.target.getAttribute('data-toggle') !== null) {
    const selector = e.target.getAttribute('data-toggle');
    const targets = document.querySelectorAll(selector);
    if (e.target.classList.contains('--active')) {
      e.target.classList.remove('--active');
    } else {
      e.target.classList.add('--active');
    }
    targets.forEach((item) => {
      item.classList.toggle('--active');
    });
  }
});

function inViewport(el, args = {
  top: 150,
  bottom: 150,
  right: -100,
  left: -10,
}) {
  const rect = el.getBoundingClientRect();
  const SDBottom = args.bottom;
  const SDTop = args.top;
  const SDRight = args.right;
  const SDLeft = args.left;

  return (
    rect.bottom - SDTop >= 0 &&
    rect.left - SDRight >= 0 &&
    rect.top + SDBottom <= (window.innerHeight ||
      document.documentElement.clientHeight) &&
    rect.right + SDLeft <= (window.innerWidth ||
      document.documentElement.clientWidth)
  );
}

function verticalInViewport(el, args = {
  top: 150,
  bottom: 150,
}) {
  const rect = el.getBoundingClientRect();
  const SDBottom = args.bottom;
  const SDTop = args.top;

  return (
    rect.bottom - SDTop >= 0 &&
    rect.top + SDBottom <= (window.innerHeight ||
      document.documentElement.clientHeight)
  );
}

function queryAllRemoveClass(elements, styleClasses) {
  let classes = styleClasses;
  if (!Array.isArray(styleClasses)) {
    classes = [styleClasses];
  }
  classes.forEach((styleClass) => {
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].classList.remove(styleClass);
    }
  });
}

function queryAllAddClass(elements, styleClasses) {
  let classes = styleClasses;
  if (!Array.isArray(styleClasses)) {
    classes = [styleClasses];
  }
  classes.forEach((styleClass) => {
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].classList.add(styleClass);
    }
  });
}

function changeStep(number) {
  // data-steps-counter
  const stepsCounter = document.querySelector('[data-steps-counter]');
  const counterNumber = stepsCounter.getAttribute('data-steps-counter');

  if (counterNumber !== number) {
    stepsCounter.setAttribute('data-steps-counter', number);

    let cls = '--going-up';
    if (counterNumber < number) {
      cls = '--going-down';
    }
    const steps = document.querySelectorAll('[data-steps] li');
    queryAllRemoveClass(steps, ['--previous', '--going-up', '--going-down']);
    queryAllAddClass(steps, [cls]);

    const prevStep = document.querySelectorAll('[data-steps] li.--active');
    queryAllAddClass(prevStep, ['--previous']);

    queryAllRemoveClass(steps, ['--active']);

    const newActiveSteps = document.querySelectorAll(`[data-steps] li:nth-child(${number})`);
    queryAllAddClass(newActiveSteps, ['--active']);
  }
}

function animate(obj, initVal, lastVal, duration) {
  let startTime = null;
  // get the current timestamp and assign it to the currentTime variable
  // pass the current timestamp to the step function
  const step = (currentTime = Date.now()) => {
    // if the start time is null, assign the current time to startTime
    if (!startTime) {
      startTime = currentTime;
    }
    // calculate the value to be used in calculating the number to be displayed
    const progress = Math.min((currentTime - startTime) / duration, 1);
    // calculate what to be displayed using the value gotten above
    // eslint-disable-next-line no-param-reassign
    obj.innerHTML = Math.floor((progress * (lastVal - initVal)) + initVal);
    // checking to make sure the counter does not exceed the last value (lastVal)
    if (progress < 1) {
      window.requestAnimationFrame(step);
    } else {
      window.cancelAnimationFrame(window.requestAnimationFrame(step));
    }
  };
  // start animating
  window.requestAnimationFrame(step);
}

let lastScrollTop = 0;

const scrollingActions = () => {
  // scroll direction start;
  const st = window.pageYOffset || document.documentElement.scrollTop;
  const downscrollTargets = document.querySelectorAll('[data-downscroll-class]');
  if (st > lastScrollTop) {
    downscrollTargets.forEach((item) => {
      item.classList.add('--downscroll');
    });
  } else {
    downscrollTargets.forEach((item) => {
      item.classList.remove('--downscroll');
    });
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  // scroll direction end;

  if (lastScrollTop === 0) {
    const targets = document.querySelectorAll('[data-on-top]');
    targets.forEach((item) => {
      item.classList.add('--on-top');
    });
  } else {
    const targets = document.querySelectorAll('[data-on-top]');
    targets.forEach((item) => {
      item.classList.remove('--on-top');
    });
  }
  document.querySelectorAll('[data-ivp-class]').forEach((element) => {
    const elClass = element.getAttribute('data-ivp-class');
    if (inViewport(element)) {
      element.classList.add(elClass);
    }
  });
  document.querySelectorAll('[data-vertical-ivp-class]').forEach((element) => {
    const elClass = element.getAttribute('data-vertical-ivp-class');
    if (verticalInViewport(element)) {
      element.classList.add(elClass);
    }
  });
  if (document.querySelector('#main-nav') !== null) {
    const darkBgElements = document.querySelectorAll('[data-dark-background]');
    const menu = document.querySelector('#main-nav');
    const rect1 = menu.getBoundingClientRect();
    let oneCollides = false;
    for (let i = 0; i < darkBgElements.length; i += 1) {
      const element = darkBgElements[i];
      const rect2 = element.getBoundingClientRect();

      const isColliding = !(
        rect1.top > rect2.bottom ||
        rect1.right < rect2.left ||
        rect1.bottom < rect2.top ||
        rect1.left > rect2.right
      );

      if (isColliding) {
        oneCollides = true;
      }
    }
    if (oneCollides) {
      menu.classList.add('--dark-bg');
    } else {
      menu.classList.remove('--dark-bg');
    }
  }
  document.querySelectorAll('[data-out-vp-class]').forEach((element) => {
    const elClass = element.getAttribute('data-out-vp-class');
    if (!inViewport(element)) {
      element.classList.add(elClass);
    } else {
      element.classList.remove(elClass);
    }
  });
  document.querySelectorAll('[data-ivp-only-class]').forEach((element) => {
    const elClass = element.getAttribute('data-ivp-only-class');
    if (inViewport(element)) {
      element.classList.add(elClass);
    } else {
      element.classList.remove(elClass);
    }
  });
  document.querySelectorAll('[data-ivp-video]').forEach((element) => {
    const args = {
      top: 350,
      bottom: 0,
      right: 0,
      left: 0,
    };
    if (!inViewport(element, args)) {
      element.pause();
    }
  });
  document.querySelectorAll('[data-dynamic-svg]').forEach((element) => {
    const time = element.getAttribute('data-dynamic-svg') * 1000;
    const entrance = element.querySelector('.svg-entrance');
    const loop = element.querySelector('.svg-loop');
    if (inViewport(element)) {
      setTimeout(() => {
        entrance.classList.add('--disabled');
        loop.classList.remove('--disabled');
      }, time);
    }
  });
  document.querySelectorAll('[data-ivp-src]').forEach((element) => {
    const elSrc = element.getAttribute('data-ivp-src');
    let args = {
      top: 200,
      bottom: 200,
      right: 0,
      left: 0,
    };
    if (!element.parentNode.classList.contains('dynamic-svg')) {
      args = {
        top: -150,
        bottom: -150,
        right: 0,
        left: 0,
      };
    }
    if (inViewport(element, args)) {
      element.setAttribute('src', elSrc);
    }
  });
  document.querySelectorAll('[data-debug]').forEach((el) => {
    const args = {
      top: 150,
      bottom: 150,
      right: 0,
      left: -100,
    };
    const rect = el.getBoundingClientRect();
    const SDBottom = args.bottom;
    const SDTop = args.top;
    const SDRight = args.right;
    const SDLeft = args.left;

    console.log('debug: 1', rect.bottom - SDTop >= 0);
    console.log('debug: 2', rect.left - SDRight >= 0);
    console.log('debug: 3', rect.top + SDBottom <= (window.innerHeight || document.documentElement.clientHeight));
    console.log('debug: 4', rect.right + SDLeft <= (window.innerWidth || document.documentElement.clientWidth));
  });
  document.querySelectorAll('[data-ivp-action]').forEach((element) => {
    const parameters = element.getAttribute('data-ivp-action');
    const action = parameters.split(',')[0];
    const parameter = parameters.split(',')[1];

    if (inViewport(element)) {
      switch (action) {
        case 'changeStep' :
          changeStep(parameter);
          break;
        default:
          console.log('No action defined');
          break;
      }
    }
  });
  document.querySelectorAll('[data-parallax]').forEach((element) => {
    let top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
    top /= 2;
    const topStyle = `transform:translate(0, calc(-50% + ${top}px ))`;
    element.setAttribute('style', topStyle);
  });
  document.querySelectorAll('[data-ivp-parallax]').forEach((element) => {
    const factor = element.getAttribute('data-ivp-parallax');
    const box = element.getBoundingClientRect();
    const vw = window.innerHeight;
    const args = {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    };
    if (inViewport(element, args)) {
      const transform = (box.top - (vw / 2)) / (2 * factor);
      const topStyle = `transform:translate(0, calc(-50% + ${transform}px))`;
      element.querySelector('div').setAttribute('style', topStyle);
    }
  });
  document.querySelectorAll('[data-toggle-top]').forEach((element) => {
    const top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
    if (top > 0 && !isNaN(top)) {
      element.classList.remove('--on-top');
    } else {
      element.classList.add('--on-top');
    }
  });
  document.querySelectorAll('[data-ivp-counter]').forEach((element) => {
    const elLimitNumber = element.getAttribute('data-ivp-counter');
    const elDuration = element.getAttribute('data-duration');
    if (inViewport(element) && element.innerHTML === '0') {
      animate(element, 0, elLimitNumber, elDuration === null ? 5000 : elDuration);
    }
  });
};

scrollingActions();

export default scrollingActions;
